<template>
  <section class="bg--sand-200">
    <div class="container">
      <article class="error-page row justify-content-center">
        <div
          class="d-flex flex-column justify-content-center align-items-center col-10 col-lg-4"
        >
          <h1 class="subtitle--big text-center my-24">{{ errorTitle }}</h1>

          <p v-if="errorBody" class="text-center">{{ errorBody }}</p>

          <div class="mt-24 mb-100 text-center">
            <nx-img
              v-if="is404"
              src="/web/img/404_cat.gif"
              alt="books illustrations"
              width="425"
              height="235"
            />

            <nuxt-link
              class="button button--primary mt-16"
              :to="localePath({ name: 'index' })"
              :aria-label="$t('404.cta')"
            >
              {{ $t('404.cta') }}
            </nuxt-link>
          </div>
        </div>
      </article>
      <DevOnly v-if="error.stack">
        <!-- v-html is safe to use in this dev-only part -->
        <!-- eslint-disable-next-line -->
        <div class="bg--sand-300 br-10 px-24 py-16" v-html="error.stack" />
        <br />
      </DevOnly>
    </div>
  </section>
</template>

<script>
export default defineNuxtComponent({
  props: {
    error: { type: Object, default: null },

    // Title and message props let you customize the default error texts, whenever needed.
    // Otherwise, some logic is there to display the default error (if you're on a 404 page, or if an issue happened)
    title: { type: String, default: null },

    message: { type: String, default: null },
  },

  computed: {
    is404() {
      return this.error?.statusCode === 404
    },

    errorTitle() {
      if (this.title) {
        return this.title
      } else if (this.is404) {
        return this.$t('404.title')
      }

      return this.$t('global.error.title')
    },

    errorBody() {
      if (this.message) {
        return this.message
      } else if (this.is404) {
        return this.$t('404.body')
      }

      return this.error?.message
    },
  },
})
</script>
