// TODO: After the nuxt 3 migration change this mixin into a composable
export default {
  methods: {
    focusTrap(dialogElement, event) {
      // Array of focusable elements in the dialog (not a NodeList anymore)
      const focusableElements = Array.from(
        dialogElement.querySelectorAll('a, button, input, textarea, [tabindex]')
      ).filter(element => this.isVisible(element)) // removing all invisible elements

      // Check if there are focusable elements
      if (focusableElements.length === 0) return

      const firstElement = focusableElements[0]
      const lastElement = focusableElements[focusableElements.length - 1]

      // Get the index of currently focused element
      const activeElementIndex = focusableElements.indexOf(
        document.activeElement
      )

      // Check if the Shift key is pressed
      if (event.shiftKey) {
        if (document.activeElement === firstElement) {
          // If it's the first element, focus on the last
          lastElement.focus()
        } else {
          // Focus on previous element
          focusableElements[Math.max(0, activeElementIndex - 1)].focus()
        }
      } else if (document.activeElement === lastElement) {
        // If it's the last element, focus on the first
        firstElement.focus()
      } else {
        // Focus on next element
        focusableElements[
          Math.min(focusableElements.length - 1, activeElementIndex + 1)
        ].focus()
      }

      // Avoid default Tab behavior
      event.preventDefault()
    },

    // Check if an element is visible in the DOM
    isVisible(element) {
      const style = window.getComputedStyle(element)

      return (
        style.display !== 'none' &&
        style.visibility !== 'hidden' &&
        style.opacity !== '0' &&
        element.offsetWidth > 0 &&
        element.offsetHeight > 0 &&
        element.getAttribute('tabindex') !== '-1' &&
        element.getAttribute('disabled') !== 'disabled' &&
        !element.classList.contains('disabled')
      )
    },
  },
}
