import { nextTick } from 'vue'
import consola from 'consola'

// This is used to make sure GTM is not loaded twice upon navigation
let isInstanced = false

export default {
  created() {
    this.trackPageView()

    if (!import.meta.client || isInstanced) return

    this.holdBackEventsUntilGTMLoaded()

    nextTick(() => {
      // Add an event listeners to the body element
      document.body.addEventListener('mousemove', this.initTrackers, {
        passive: true,
      })
      document.body.addEventListener('click', this.initTrackers)
      document.body.addEventListener('touchstart', this.initTrackers)
      document.body.addEventListener('scroll', this.initTrackers, {
        passive: true,
      })

      // This is needed so CookieBot don't override the localStorage
      window.addEventListener('CookiebotOnAccept', this.initLocalStorage)
      window.addEventListener('CookiebotOnAccept', this.updateConsent)
      window.addEventListener('CookiebotOnDecline', this.initLocalStorage)

      // Load the trackers after the page is fully loaded, a maximum of 5 seconds after the page is fully loaded
      const now = Date.now()
      const domLoadedTime = new Date(
        performance?.timing?.domLoading || now + 5000
      )
      const maximumWaitTime = 5000
      const delay = Math.max(10, Math.min(maximumWaitTime, now - domLoadedTime))
      setTimeout(() => {
        this.initTrackers()
      }, delay)

      // If the page has any utm parameters, we want to init the trackers immediately
      // Note: It's generic and check all possible utm_* parameters
      const hasUTM = Object.keys(this.$route.query).some(key =>
        key.startsWith('utm_')
      )
      if (hasUTM) {
        this.initTrackers()
      }
    })
  },

  destroyed() {
    this.removeListeners()
  },

  watch: {
    '$route.path'() {
      this.trackPageView()
    },
  },

  methods: {
    gtag() {
      window.dataLayer = window.dataLayer || []

      return dataLayer.push(arguments)
    },

    removeListeners() {
      // Remove the event listeners from the body element
      document.body.removeEventListener('mousemove', this.initTrackers)
      document.body.removeEventListener('click', this.initTrackers)
      document.body.removeEventListener('touchstart', this.initTrackers)
      document.body.removeEventListener('scroll', this.initTrackers)

      // Remove CookieBot event listeners
      window.removeEventListener('CookiebotOnAccept', this.initLocalStorage)
      window.removeEventListener('CookiebotOnDecline', this.initLocalStorage)
    },

    initTrackers() {
      if (isInstanced) return

      isInstanced = true
      this.removeListeners()

      if (this.$route.query.disableExternalScripts !== undefined) {
        // If we have disableExternalScripts in the query params, we don't want to load the trackers

        // eslint-disable-next-line no-console
        console.log('🧪 External scripts are disabled')

        return
      }

      // Load CookieBot
      const script = document.createElement('script')
      script.async = true
      script.id = 'Cookiebot'
      script.src = 'https://consent.cookiebot.com/uc.js'
      // Manual loading mode is needed, or else it causes Adyen secureFields loading issues
      script.setAttribute('data-cbid', 'f682a2e0-d5e4-4721-b2d7-4cb1e693f11a')
      script.type = 'text/javascript'

      document.head.prepend(script)

      /**
       * Initialize GTM
       */
      this.gtag('consent', 'default', {
        ad_personalization: 'denied' /* marketing cookies */,
        ad_storage: 'denied' /* marketing cookies */,
        ad_user_data: 'denied' /* marketing cookies */,
        analytics_storage: 'denied' /* statistics cookies */,
        functionality_storage: 'denied',
        personalization_storage: 'denied',
        security_storage: 'denied',
        wait_for_update: 500,
      })

      this.$gtm?.enable()
    },

    initLocalStorage() {
      const virtualURL = window.location.pathname
      let originalLocation = localStorage.getItem('originalLocation.url')
      let originalLocationExpireTime =
        localStorage.getItem('originalLocation.expires') || 0

      if (!originalLocation || originalLocationExpireTime < Date.now()) {
        // first visit or expired session

        originalLocation = window.location.href
        originalLocationExpireTime = Date.now() + 30 /* minutes */ * 1000 * 60

        localStorage.setItem('originalLocation.url', originalLocation)
        localStorage.setItem(
          'originalLocation.expires',
          originalLocationExpireTime.toString()
        )
      }

      return { virtualURL, originalLocation }
    },

    updateConsent() {
      // We are granting and denying the consent for the different types of cookies
      // We need to keep both condition if user update the consent

      this.gtag('consent', 'update', {
        ad_personalization: Cookiebot.consent.marketing ? 'granted' : 'denied',
        ad_storage: Cookiebot.consent.marketing ? 'granted' : 'denied',
        ad_user_data: Cookiebot.consent.marketing ? 'granted' : 'denied',
        analytics_storage: Cookiebot.consent.statistics ? 'granted' : 'denied',
        functionality_storage: 'granted',
        personalization_storage: 'granted',
        security_storage: 'granted',
      })
    },

    holdBackEventsUntilGTMLoaded() {
      /*
       The goal of this method is to hold back events until GTM is loaded
       The reason is that our custom events (page_view, view_item, etc.) should only be pushed to the dataLayer _after_ GTM is loaded (so that we can respect the user consent on each individual event)

       How is this done? We override the dataLayer.push method and hold back events until GTM is fully loaded, then we restore the held back events.
      */

      window.dataLayer = window.dataLayer || []
      const originalPush = window.dataLayer.push
      const heldBackEvents = window.dataLayer

      const statuses = {
        PRELOAD: 'PRELOAD', // Before GTM is loaded
        LOADING: 'LOADING', // While GTM is loading (from 'gtm.js' to 'gtm.load' events)
        LOADED: 'LOADED', // After GTM is loaded
      }

      let status = statuses.PRELOAD

      // Edge case: if GTM is already loaded at this point, we can't do anything (shouldn't happen, just a failsafe)
      if (window.dataLayer.find(event => event?.event === 'gtm.load')) {
        return
      }

      // Clear existing dataLayer of non-allowed events (if any)
      window.dataLayer = window.dataLayer.filter(
        event =>
          event?.event &&
          (status !== statuses.PRELOAD || event?.event === 'gtm.js')
      )

      // Override the dataLayer.push method
      window.dataLayer.push = (...args) => {
        // If GTM is loaded, push to dataLayer normally
        if (status === statuses.LOADED) {
          return originalPush.apply(window.dataLayer, args)
        }

        // Define the event
        const event = args[0]

        // Check if event is allowed to be pushed before GTM is loaded
        if (status !== statuses.PRELOAD || event?.event === 'gtm.js') {
          originalPush.apply(window.dataLayer, args)

          // Start allowing all events after 'gtm.js'
          if (event?.event === 'gtm.js') {
            status = statuses.LOADING
          }

          // Once GTM has finished loading, push held back events to dataLayer
          if (event?.event === 'gtm.load') {
            status = statuses.LOADED
            heldBackEvents.forEach(heldBackEvent => {
              this.$gtm?.push(heldBackEvent)
            })
          }
        } else {
          // Hold back event until GTM is loaded
          heldBackEvents.push(event)
        }
      }
    },

    trackPageView() {
      if (!import.meta.client) return

      const { originalLocation, virtualURL } = this.initLocalStorage()

      try {
        this.$gtm?.push({
          event: 'page_view',
          virtualURL,
          originalLocation,
        })
      } catch (trackingError) {
        // Log tracking error, important to track them all!
        const logger = consola.withTag('Mixin- tracker')

        this.$sentry.captureException(trackingError)
        logger.warn(trackingError)
      }
    },
  },
}
