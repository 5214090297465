<template>
  <header
    :class="[
      isTransparent ? 'header--transparent w-100' : 'bg--sand-200',
      isOverlaid ? 'position-absolute' : '',
    ]"
  >
    <section
      class="container ps-24 pe-fix-burger py-16 py-sm-32 py-md-40 d-flex justify-content-between align-items-center"
    >
      <nuxt-link
        :to="localePath({ name: 'index' })"
        :aria-label="$t('homepage.menu_home')"
        class="d-block text--pink-400"
      >
        <span class="nx-header-logo" />
      </nuxt-link>
      <section class="d-flex">
        <nav class="d-flex">
          <header-search
            :label="$t('catalogue.search.input_label')"
            icon="icon--navigation-search"
            :dark-theme="isTransparent"
            data-test="search-icon"
          />

          <div
            class="vr mx-4"
            :class="[hasNavIconsLight ? 'text--gray-200' : 'text--gray-700']"
          />

          <nuxt-link
            v-if="!$auth.loggedIn"
            :to="localePath({ name: 'profile-login' })"
            tabindex="0"
            class="d-flex align-items-center button-header"
            data-test="login-link"
            :aria-label="$t('global.button_login')"
          >
            <span
              class="d-none d-md-inline-block text--meta pe-4"
              :class="[hasNavIconsLight ? 'text--sand-200' : 'text--sand-700']"
            >
              {{ $t('global.button_login') }}
            </span>
            <span
              class="icon-24 icon--navigation-profile"
              :class="[hasNavIconsLight ? 'text--sand-200' : 'text--sand-700']"
            />
          </nuxt-link>

          <nuxt-link
            v-else
            :to="localePath({ name: 'profile-profile' })"
            tabindex="0"
            class="d-flex align-items-center button-header"
            :aria-label="$auth.user.first_name || $t('mypages.title')"
          >
            <span
              class="d-none d-md-inline-block text--meta pe-4"
              data-test="link-to-mypages"
              :class="[hasNavIconsLight ? 'text--pink-400' : 'text--sand-700']"
            >
              {{ $auth.user.first_name || $t('mypages.title') }}
            </span>
            <span
              class="icon-24 icon--navigation-profile"
              :class="[hasNavIconsLight ? 'text--pink-400' : 'text--sand-700']"
            />
          </nuxt-link>

          <div
            class="vr mx-4"
            :class="[hasNavIconsLight ? 'text--sand-200' : 'text--sand-700']"
          />

          <header-navigation :has-nav-icons-light="hasNavIconsLight" />
        </nav>
      </section>
    </section>
  </header>
</template>

<script>
export default defineNuxtComponent({
  props: {
    hasNavIconsLight: { type: Boolean, default: false },
    isTransparent: { type: Boolean, default: false },
    isOverlaid: { type: Boolean, default: false },
  },

  head(nuxtApp) {
    const { $store } = nuxtApp

    return {
      htmlAttrs: {
        class: $store.getters.asFullscreenComponent ? 'overflow-hidden' : '',
      },
    }
  },
})
</script>

<style lang="scss">
.button-header {
  padding: 1.2rem;
  color: inherit;
  text-decoration: none;
  background-color: transparent;
  border: none;
  transition: color 150ms;
}

.header {
  // .header__label
  &__label {
    font-weight: normal;
    cursor: pointer;
  }

  // .header__reset-button
  &__reset-button {
    position: absolute;
    right: 0;
  }

  // .header--transparent
  &--transparent {
    z-index: 2;
    background-color: transparent;
  }
}

.main-navigation {
  position: fixed;
  z-index: 100;
  inset: 0;
  width: 100%;
  height: 100%;
  overflow: auto; // Let scroll in the main navigation, for smaller screens. Not perfect: it may scroll the whole page behind it too.
}

.navigation-list {
  margin: 0; // ul reset
  padding: 0; // ul reset
  list-style: none;

  &__items {
    padding: 0.6rem 0;
  }
}

.vr {
  width: 0.1rem;
  // vertical hr
  height: 2.4rem;
  margin: auto 1.2rem;
  opacity: 1;
}

// used to deal with the optical white space of the burger button.
.pe-fix-burger {
  padding-right: 0.9rem !important;
}
</style>
